import React from 'react'
import { Link } from "gatsby"
import { motion } from "framer-motion"

// GQL Fragments
import { useLatestPosts } from "../../GraphQL/useLatestPosts"

// Components
import { MotionWraper } from "./motionWraper"
import { BlogCard } from "../ui"

const aniVars = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2,
      duration:0.8,
    },
  },
};

const LatestJournal = ({ heading, spacing, pages }) => {

  const latestEvents = useLatestPosts()  

  return (
    <div>
      <MotionWraper
        className={`overflow-x-hidden grid grid-cols-14 gap-y-8 lg:gap-y-16 pb-24 lg:pb-48`}
        spacing={spacing}
      >
        <div className="z-20 row-start-1 col-start-2 col-end-14 flex items-end justify-between">
          <h3 className="w-1/2 text-xl lg:text-5xl tracking-wider font-bold uppercase">
            {heading ? heading : 'Journal'}
          </h3>
          <Link className="text-sm underline lg:font-medium 2xl:text-base" to={`/journal`}>
            View Journal
          </Link>
        </div>
        <motion.div
          variants={aniVars}
          className="grid row-start-2 col-start-2 col-end-14 grid-flow-row grid-cols-1 md:grid-cols-2 gap-8"
        >
          {latestEvents.map((item) => (
            <BlogCard className={`col-span-1`} item={item} />
          ))}
        </motion.div>        
      </MotionWraper>
    </div>
  );
}

export default LatestJournal
