import React from "react"

import { MotionWraper } from './motionWraper';
import { VideoModule } from "../modules"

const VideoBlock = ({ spacing, video }) => {
  return (
    <MotionWraper className="grid grid-cols-14" spacing={spacing}>
      <section className="col-start-2 col-end-14">
        <VideoModule {...video} />
      </section>
    </MotionWraper>
  );
}

export default VideoBlock
