import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { motion, AnimatePresence } from 'framer-motion';

const imageVariants = {
  initial: {
    scale: 1.4,
  },
  enter: {
    scale: 1,
    transition: {
      delay: 0,
      duration: 1,
    },
  },
  exit: {},
};

const imgWrapVariants = {
  initial: {
    left: '0',
    top: '0',
    bottom: '0',
    height: '100vh',
  },
  enter: {
    left: '40%',
    top: '100px',
    bottom: '4vh',
    height: 'calc(100vh - (4vh + 100px))',
    transition: {
      ease: 'easeInOut',
      delay: 1.5,
      duration: 1,
    },
  },
  exit: {},
};

const imgMaskVariants = {
  initial: {
    width: '0%',
  },
  enter: {
    width: '43%',
    transition: {
      ease: 'easeInOut',
      delay: 1.5,
      duration: 1,
    },
  },
  exit: {},
};

const headingVariants = {
  initial: {
    opacity: 0,
    y: '200px',
    rotateX: '-80deg',
  },
  enter: {
    opacity: 1,
    y: '0',
    rotateX: '0deg',
  },
  exit: {},
};

// Fullscreen wrap image, no position change
const imgWrapVariantsFullscreen = {
  initial: {
    left: '0',
    top: '0',
    bottom: '0',
    height: '100vh',
  },
  enter: {
    bottom: '4vh',
    height: 'calc(100vh - 4vh)',
    transition: {
      ease: 'easeInOut',
      delay: 0,
      duration: 1,
    },
  },
  exit: {},
};

const PageBannerDesk = ({ className, outlineText, solidText, imageWidth, image }) => {
  return (
    <div className={`${className}`}>
      <AnimatePresence>
        <div className="desk z-10 lg:min-h-screen overflow-hidden relative grid grid-rows-1 grid-cols-14">
          <h1
            data-scroll
            data-scroll-speed={4}
            style={{ lineHeight: '1' }}
            className="z-20 transform translate-y-2 row-start-1 col-start-2 col-end-13 lg:col-end-11 2xl:col-end-11 3xl:col-end-10 flex flex-col justify-end pb-0 text-4xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-10xl font-sans font-bold uppercase pr-8 lg:pr-0 text-current"
          >
            <motion.span
              className="transform-gpu outline-heading origin-center"
              variants={headingVariants}
              initial="initial"
              animate="enter"
              exit="exit"
              transition={{
                type: 'ease-out',
                damping: 10,
                delay: imageWidth === 'half' ? 1.9 : 0.9,
                duration: 0.8,
              }}
            >
              {outlineText && outlineText}
            </motion.span>
            <motion.span
              className="transform-gpu origin-center"
              variants={headingVariants}
              initial="initial"
              animate="enter"
              exit="exit"
              transition={{
                type: 'ease-out',
                damping: 10,
                delay: imageWidth === 'half' ? 2 : 1,
                duration: 0.8,
              }}
            >
              {solidText && solidText}
            </motion.span>
          </h1>
          {image.image && (
            <>
              {imageWidth === 'half' ? (
                <>
                  <motion.div
                    variants={imgMaskVariants}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    className="transform-gpu absolute top-0 left-0 bottom-0 z-10 bg-dark"
                  />
                  <motion.div
                    variants={imgWrapVariants}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    className="transform-gpu absolute right-0 z-0"
                  >
                    <motion.div
                      className="transform-gpu w-full h-full bg-red-500 relatve"
                      variants={imageVariants}
                      initial="initial"
                      animate="enter"
                      exit="exit"
                    >
                      <SanityImage className="w-full h-full object-cover" {...image.image} />
                    </motion.div>
                  </motion.div>
                </>
              ) : (
                <>
                  <motion.div
                    variants={imgWrapVariantsFullscreen}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    className="transform-gpu absolute left-0 top-0 bottom-0 right-0 z-0"
                  >
                    <motion.div
                      className="transform-gpu w-full h-full bg-red-500 relatve"
                      variants={imageVariants}
                      initial="initial"
                      animate="enter"
                      exit="exit"
                    >
                      {image.image && (
                        <SanityImage className="w-full h-full object-cover" {...image.image} />
                      )}
                    </motion.div>
                  </motion.div>
                </>
              )}
            </>
          )}
        </div>
      </AnimatePresence>
    </div>
  );
};

export default PageBannerDesk;
