import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"




const QuotesCarousel = ({ className, children }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const wrappedIndex = wrap(0, children.length, slideIndex)

  return (
    <div className={`relative ${className}`}>
      <nav className="mb-8 lg:mb-12">
        {children.map((_, index) => {
          return (
            <button
              key={index}
              onClick={() => setSlideIndex(index)}
              className={`outline-remove text-current text-xl mr-4 border-2 h-10 w-10 rounded-full ${
                index === slideIndex
                  ? "border-current"
                  : "border-transparent opacity-50"
              }`}
            >
              {index + 1}
            </button>
          )
        })}
      </nav>
      <AnimatePresence exitBeforeEnter>
        <div
          {...children[wrappedIndex]}
          key={slideIndex}
          drag="x"
          dragMomentum={false}
          dragElastic={false}
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={(e, { offset, velocity }) => {
            if (offset.x > 100) {
              setSlideIndex(slideIndex - 1)
            } else if (offset.x < -100) {
              setSlideIndex(slideIndex + 1)
            }
          }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {children[wrappedIndex]}
          </motion.div>
        </div>
      </AnimatePresence>
    </div>
  )
}

export default QuotesCarousel
