import React from 'react'
import { motion } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"
import { PortableTextBlock } from "../sanity/portableTextBlock"

// Animation
const aniVars = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 50,
    opacity: 0,
  },
};

const TextBlock = ({ _rawText, spacing, position, measureText }) => {
   var alignContent = ""
   if (position === "left") {
     alignContent = `col-start-2 col-end-12 lg:col-end-10 xl:col-end-8 2xl:col-end-7`;
   }
   if (position === "right") {
     alignContent = `col-start-4 col-end-14 lg:col-start-9 xl:col-start-7 lg:col-end-13 2xl:col-start-8`;
   }
   if (position === "center") {
     alignContent = `col-start-2 col-end-14 lg:col-start-4 lg:col-end-12 2xl:col-start-5 2xl:col-end-11`
   }
   if (position === "full") {
     alignContent = `col-start-2 col-end-10 lg:col-start-2 lg:col-end-15`
   }


  return (
    <MotionWraper className={`grid grid-cols-14`} spacing={spacing}>
      <motion.div
        data-scroll
        data-scroll-speed="1"
        className={`text-current prose lg:prose-lg 2xl:prose-xl max-w-none ${alignContent}`}
        variants={aniVars}
        transition={{
          type: 'ease-out',
          damping: 3,
          delay: 0.3,
          duration: 0.7,
        }}
      >
        {_rawText && <PortableTextBlock text={_rawText} />}
      </motion.div>
    </MotionWraper>
  );
}

export default TextBlock
