import React from 'react';
import SanityImage from 'gatsby-plugin-sanity-image';
import { motion, AnimatePresence } from 'framer-motion';

const headingVariants = {
  initial: {
    opacity: 0,
    y: '200px',
    rotateX: '-80deg',
  },
  animate: {
    opacity: 1,
    y: '0',
    rotateX: '0deg',
  },
};

const imageVariants = {
  initial: {
    scale: 1.4,
  },
  animate: {
    scale: 1,
    transition: {
      delay: 0,
      duration: 1,
    },
  },
};

const PageBannerMobile = ({ className, outlineText, solidText, image }) => {
  return (
    <div className={`${className}`}>
      <AnimatePresence exitBeforeEnter>
        <motion.div className="mobile z-10 relative grid grid-rows-1 grid-cols-14">
          <h1
            style={{ lineHeight: '1.1' }}
            className="z-20 transform translate-y-8 row-start-1 col-start-2 col-end-14 lg:col-end-12 flex flex-col justify-end pb-0 text-5xl md:text-7xl lg:text-8xl xl:text-9xl 2xl:text-11xl font-sans font-bold uppercase pr-8 lg:pr-0 text-current"
          >
            <motion.span
              className="transform-gpu outline-heading origin-center"
              initial="initial"
              animate="animate"
              exit="initial"
              variants={headingVariants}
              transition={{
                type: 'ease-out',
                damping: 10,
                delay: 1,
                duration: 0.8,
              }}
            >
              {outlineText && outlineText}
            </motion.span>
            <motion.span
              className="transform-gpu origin-center"
              initial="initial"
              animate="animate"
              exit="initial"
              variants={headingVariants}
              transition={{
                type: 'ease-out',
                damping: 10,
                delay: 1.1,
                duration: 0.8,
              }}
            >
              {solidText && solidText}
            </motion.span>
          </h1>
          <motion.div
            className="row-start-1 col-start-1 col-end-15 w-full h-half-screen relative z-0"
            initial="initial"
            animate="animate"
            exit="initial"
            variants={imageVariants}
          >
            {image.image && <SanityImage className="w-full h-full object-cover" {...image.image} />}
          </motion.div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default PageBannerMobile;
