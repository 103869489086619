import React from 'react'
import { motion } from 'framer-motion';
import Image from "gatsby-plugin-sanity-image"

// Components
import { MotionWraper } from "./motionWraper"


const aniVars = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};


const ImageGrid = ({ layout, spacing, items }) => {

  let layoutGrid = ""
  if (layout === '3' ) {
    layoutGrid = "lg:grid-cols-3"    
  } else {
    layoutGrid = 'md:grid-cols-2';    
  }

  return (
    <MotionWraper className={`grid grid-cols-14`} spacing={spacing}>
      <div
        variants={aniVars}
        className={`col-start-2 col-end-14 grid grid-cols-1 gap-12 ${layoutGrid} grid-flow-row`}
      >
        {items.map((item, index) => {

          let delayValue  = ((index) / 10) * 6;
          delayValue = Math.round(delayValue * 100) / 100;
          return (
            <motion.div
              variants={aniVars}
              transition={{
                type: 'ease-out',
                damping: 7,
                delay: delayValue,
                duration: 0.6,
              }}
              className="col-span-1"
            >
              {item && <Image {...item} className="w-full h-auto" />}
            </motion.div>
          );
        })}
      </div>
    </MotionWraper>
  );
}

export default ImageGrid;
