import React from 'react'
import { motion } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"
import { PortableTextBlock } from "../sanity/portableTextBlock"

// Animation
const columnVariants = {
  visible: {
    opacity: 1,
    y: "0",
  },
  hidden: {
    opacity: 0,
    y: 50,
  },
}

const TextBlockTwoCol = ({ _rawTextLeft, _rawTextRight, spacing }) => {  

  return (
    <MotionWraper
      spacing={spacing}
      className={`grid grid-cols-14 gap-y-4 lg:gap-y-0 text-current prose lg:prose-lg 2xl:prose-xl max-w-none`}
    >
      <motion.div
        className="col-start-2 col-end-14 row-start-1 lg:col-end-8 lg:pr-6 2xl:col-start-3"
        variants={columnVariants}
        transition={{
          type: 'ease-out',
          damping: 3,
          delay: 0.5,
          duration: 0.7,
        }}
      >
        {_rawTextLeft && <PortableTextBlock text={_rawTextLeft} />}
      </motion.div>
      <motion.div
        // data-scroll
        // data-scroll-speed="-2"
        // data-scroll-delay="0.01"
        className="col-start-2 col-end-14 lg:col-start-8 row-start-2 lg:row-start-1 lg:pl-6 2xl:col-end-13"
        variants={columnVariants}
        transition={{
          type: 'ease-in-out',
          damping: 10,
          delay: 0.3,
          duration: 0.9,
        }}
      >
        {_rawTextRight && <PortableTextBlock text={_rawTextRight} />}
      </motion.div>
    </MotionWraper>
  );
}

export default TextBlockTwoCol
