import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { motion } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"
import { SingleImageCarousel } from "../carousels"

// Animation
const aniVars = {
  visible: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 30,
    opacity: 0,
  },
};

const ImageCarousel = ({spacing, items}) => {

  return (
    <MotionWraper className={`text-white`} spacing={spacing}>
      <motion.div
        variants={aniVars}
        transition={{
          type: 'ease-out',
          damping: 10,
          delay: 0.3,
          duration: 0.7,
        }}
      >
        <SingleImageCarousel items={items}>
          {items.map((item) => (
            <>
              <div className="grid grid-cols-14 relative">
                {item.image && (
                  <div className="w-full z-0 relative row-start-1 col-start-1 col-end-15 aspect-w-16 aspect-h-10 overflow-y-hidden">
                    <motion.div className="aspect-w-12 aspect-h-8">
                      <Image {...item.image} className="absolute w-full h-full object-cover" />
                    </motion.div>
                  </div>
                )}
                {item.caption && (
                  <div className="row-start-1 col-start-2 col-end-12 flex items-end pb-grid z-10">
                    <motion.h3
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 20 }}
                      transition={{
                        duration: 0.6,
                        delay: 0.5,
                        damping: 0.7,
                      }}
                      className="text-lg md:text-2xl lg:text-3xl text-white"
                    >
                      {item.caption}
                    </motion.h3>
                  </div>
                )}
              </div>
            </>
          ))}
        </SingleImageCarousel>
      </motion.div>
    </MotionWraper>
  );
}

export default ImageCarousel
