import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { motion } from "framer-motion"
import { PortableTextBlock } from '../sanity/portableTextBlock';

// Animation
const majorVars = {
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      delay: 0.3,
      duration: 0.9,
    },
  },
  hidden: {
    opacity: 0,
    x: 20,
    y: 50,    
  },
};

const minorVars = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.3,
      duration: 0.9,
    },
  },
  hidden: {
    opacity: 0,
    x: 20,
  },
};

const imgVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    borderRadius: 0,
    transition: {
      duration: 0.9,
    },
  },
  hidden: {
    scale: 0.9,
    borderRadius: 20,
    opacity: 0,    
  },
}

const ProjectBanner = ({ minorHeading, featureHeading, image, mainHeading }) => {
  return (
    <div className="grid grid-rows-1 grid-cols-14">
      <motion.div
        data-scroll
        data-scroll-speed="-3"
        className="col-start-2 col-end-14 row-start-1 z-20 text-white flex flex-col justify-center lg:justify-start py-32 lg:py-48 2xl:pt-56"
      >
        {minorHeading && (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={minorVars}
            className="tracking-wider lg:text-xl 2xl:text-3xl lg:mb-6 font-medium"
          >
            {minorHeading}
          </motion.div>
        )}
        {mainHeading ? (
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={majorVars}
            className="font-serif text-4xl lg:text-7xl 2xl:text-8xl"
            style={{ lineHeight: '1.1'}}
          >
            <PortableTextBlock text={mainHeading} />
          </motion.h1>
        ) : (
          <>
            {featureHeading && (
              <motion.h1
                initial="hidden"
                animate="visible"
                variants={majorVars}
                className="font-serif text-4xl lg:text-7xl 2xl:text-8xl"
              >
                {featureHeading}
              </motion.h1>
            )}
          </>
        )}
      </motion.div>
      <div className="col-start-1 col-end-15 row-start-1 w-full bg-dark bg-opacity-20 z-10"></div>
      {image.image && (
        <motion.div
          variants={imgVariants}
          initial="hidden"
          animate="visible"
          className="col-start-1 col-end-15 row-start-1 w-full aspect-w-4 aspect-w-4 lg:h-screen z-0"
        >
          {image && <Image className="w-full h-full object-cover" {...image.image} />}
        </motion.div>
      )}
    </div>
  );
}

export default ProjectBanner
