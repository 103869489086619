import React, { useState, useEffect, useCallback } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import { setupWheelGestures } from "embla-carousel-wheel-gestures"

const MotionCarousel = ({ children, className }) => {
  const [scrollProgress, setScrollProgress] = useState(0)

  const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start" })

  const onScroll = useCallback(() => {
    if (!emblaApi) return
    setScrollProgress(emblaApi.scrollProgress() * 100)
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on("scroll", onScroll)
      setupWheelGestures(emblaApi)
    }
  }, [emblaApi, onScroll])

  return (
    <div className={`${className} carouselVisible`} ref={emblaRef}>
      <div className="canvas flex">{children}</div>
    </div>
  )
}

export default MotionCarousel
