import React from 'react'
import { motion } from 'framer-motion';
import Image from "gatsby-plugin-sanity-image"

import { StandardBlockWrapper } from './standardBlockWrapper';
import { MotionWraper } from './motionWraper';
import { PortableTextBlock } from '../sanity/portableTextBlock';  

const aniVars = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const textVars = {
  hidden: {
    opacity: 0,
    x: '10%',
  },
  visible: {
    opacity: 1,
    x: 0,
  },
};

const PartnerGrid = ({ spacing, _rawText, items}) => {
  return (
    <StandardBlockWrapper className="grid grid-cols-14 overflow-x-hidden" spacing={spacing}>
      <div className="row-span-full col-start-2 col-end-14 xl:col-end-12 2xl:col-end-8 text-current prose lg:prose-lg 2xl:prose-xl max-w-none mb-16 2xl:mb-20">
        {_rawText && <PortableTextBlock text={_rawText} />}
      </div>
      <MotionWraper className={`row-start-2 col-start-2 col-end-14`}>
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-12 2xl:gap-x-40 2xl:gap-y-12 grid-flow-row">
          {items.map((item, index) => {
            let delayValue = (index / 10) * 0.5;
            delayValue = Math.round(delayValue * 100) / 100;
            return (
              <>
                {item.asset.url && (
                  <motion.div
                    variants={aniVars}
                    transition={{
                      delay: delayValue,
                      duration: 0.3,
                    }}
                    className="col-span-1"
                  >
                    <Image {...item} className="w-full h-auto" />
                  </motion.div>
                )}
              </>
            );
          })}
        </div>
      </MotionWraper>
    </StandardBlockWrapper>
  );
}

export default PartnerGrid
