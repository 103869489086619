import React from 'react'
import { CtaContent } from "../ui"



const Cta = ({ spacing }) => {
  
  return (
    <>
    <CtaContent spacing={spacing}/>
    </>
    
  )
}

export default Cta
