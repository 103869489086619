import React from "react"
import PortableText from "react-portable-text"

import { PageLinkPortable, PathLink, ExternalLinkPortable } from '../ui/links';

export const PortableTextBlock = ({ className, text, ...props }) => {
  return (
    <div className={className}>
      <PortableText
        // Pass in block content straight from Sanity.io
        className={`block-content font-serif`}
        content={text}
        // Optionally override marks, decorators, blocks, etc. in a flat
        // structure without doing any gymnastics
        serializers={{
          h1: (props) => (
            <h1
              className="font-sans"
              style={{ color: 'inherit', fontWeight: 'normal', lineHeight: '1.7' }}
              {...props}
            />
          ),
          h2: (props) => (
            <h2
              className="font-sans"
              style={{ color: 'inherit', fontWeight: 'normal', lineHeight: '1.7' }}
              {...props}
            />
          ),
          h3: (props) => (
            <h3
              className="font-sans"
              style={{ color: 'inherit', fontWeight: 'normal', lineHeight: '1.7' }}
              {...props}
            />
          ),
          h4: (props) => (
            <h4
              className="font-sans"
              style={{ color: 'inherit', fontWeight: 'normal', lineHeight: '1.7' }}
              {...props}
            />
          ),
          h5: (props) => (
            <h5
              className="font-sans"
              style={{ color: 'inherit', fontWeight: 'normal' }}
              {...props}
            />
          ),
          h6: (props) => (
            <h6
              className="font-sans"
              style={{ color: 'inherit', fontWeight: 'normal' }}
              {...props}
            />
          ),
          pathLink: (props) => <PathLink {...props} />,
          pageLink: (props) => <PageLinkPortable {...props} />,
          externalLink: (props) => <ExternalLinkPortable {...props} />,
        }}
      />
    </div>
  );
}
