import React from 'react'
import { Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image"

// Components
import { StandardBlockWrapper } from "./standardBlockWrapper"
import { MotionCarousel } from "../carousels"

const RelatedProjects = ({ items }) => {
  return (
    <div>
      <StandardBlockWrapper
        className={`grid grid-cols-14 gap-y-8 pt-24 lg:pt-48 2xl:pt-96`}
      >
        <div className="z-0 row-start-1 col-start-2 col-end-14 lg:col-end-4 flex items-end lg:items-start justify-between">
          <h3
            style={{ lineHeight: 1 }}
            className="w-1/2 lg:w-full text-xl lg:text-5xl font-bold uppercase lg:transform lg:rotate-90 lg:origin-bottom-left lg:-translate-y-full"
          >
            Other Projects
          </h3>
          <Link className="text-sm lg:hidden underline" to={`/projects`}>
            View Projects
          </Link>
        </div>
        <div className="row-start-2 lg:row-start-1 col-start-2 col-end-14 lg:col-start-4 lg:col-end-12">
          <MotionCarousel className={`z-10 w-full cursor-drag`}>
            {items.edges.map(item => {
              return (
                <Link
                  to={`/projects/${item.node.main.slug.current}`}
                  className="cursor-drag no-underline group w-full pr-4 lg:pr-12 flex-slide-item"
                >
                  <div className="aspect-w-3 aspect-h-2">
                    <Image
                      className={`w-full h-full object-cover`}
                      {...item.node.portalImage.image}
                    />
                  </div>
                  <div className="cursor-pointer flex flex-col justify-between text-sm lg:text-base mt-4">
                    <h4 className="no-underline block mt-4 lg:mt-6 font-medium text-lg 2xl:text-3xl md:text-2xl">
                      {item.node.main.title}
                    </h4>
                    <span className="underline hidden md:block opacity-0 group-hover:opacity-100 transition duration-200">
                      View project
                    </span>
                  </div>
                </Link>
              );
            })}
          </MotionCarousel>
        </div>
      </StandardBlockWrapper>
    </div>
  )
}

export default RelatedProjects
