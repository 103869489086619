import React from "react"

// Hooks
import { useTailPadBot, useTailPadTop } from "../../hooks"

export const StandardBlockWrapper = ({
  spacing,
  children,
  className,
}) => {
  const paddingTop = useTailPadTop((spacing && spacing.padTop) || '0');
  const paddingBottom = useTailPadBot((spacing && spacing.padBot) || '0');

  return (
    <section      
      className={`overflow-x-hidden ${paddingTop} ${paddingBottom} ${className}`}
    >
      {children}
    </section>
  )
}
