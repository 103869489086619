import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { PortableTextBlock } from '../sanity/portableTextBlock';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const EnquireForm = ({ submissionText }) => {
  const inputClasses =
    'bg-transparent border-b border-black w-full placeholder-current outline-none focus:outline-none py-2';

  const radioClasses =
    'mr-2 appearance-none w-4 h-4 rounded-full border border-pink outline-none focus:outline-none';

  const errorClasses = 'tracking-wide text-sm my-2 text-left';
  return (
    <Formik
      initialValues={{
        firstName: '',
        surname: '',
        email: '',
        phone: '',
        interest: '',
      }}
      validate={(values) => {
        const errors = {};
        // Validate Name Field
        if (!values.firstName) {
          errors.firstName = 'First Name is Required';
        }
        if (!values.phone) {
          errors.phone = 'Phone Number is required';
        }
        // Validate Email Address
        if (!values.email) {
          errors.email = 'Email address is Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': `pop-enquiry`,
            ...values,
          }),
        })
          .then(() => {
            setSubmitting(false);
            setStatus('success');
          })
          .catch((error) => alert(error));
      }}
    >
      {({
        onChange,
        isSubmitting,
        status,
        values,
        /* and other goodies */
      }) => (
        <Form
          className="w-full relative mt-10 text-wonder-green"
          name="pop-enquiry"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          subject="POP Enquiry"
        >
          {/* Hidden Fields for netlify */}
          <input type="hidden" name="pop-enquiry" value="pop-enquiry" />
          <p className="hidden">
            <label>
              <input name="bot-field" onChange={onChange} />
            </label>
          </p>
          <>
            <div
              className={`prose lg:prose-xl text-black transition-all delay-300 duration-300 absolute top-0 left-0 right-0 z-10 ${
                status === 'success'
                  ? 'opacity-100 pointer-events-all'
                  : 'opacity-0 pointer-events-none'
              }`}
            >
              {submissionText ? (
                <PortableTextBlock text={submissionText} />
              ) : (
                <p>Thank you we will be in touch</p>
              )}
            </div>
            <div
              className={`grid gap-x-12 grid-cols-1 font-body text-medium transition-all duration-300 gap-y-6 ${
                status === 'success' && 'opacity-0 pointer-events-none'
              }`}
            >
              <div>
                <Field
                  type="text"
                  name="firstName"
                  className={inputClasses}
                  placeholder="First Name"
                />
                <ErrorMessage name="firstName" className={errorClasses} component="div" />
              </div>
              <div>
                <Field
                  type="text"
                  name="surname"
                  className={inputClasses}
                  placeholder="Last Name"
                />
              </div>
              <div>
                <Field
                  type="email"
                  name="email"
                  className={inputClasses}
                  placeholder="Email Address"
                />
                <ErrorMessage name="email" className={errorClasses} component="div" />
              </div>
              <div>
                <Field
                  type="text"
                  name="phone"
                  className={inputClasses}
                  placeholder="Phone Number"
                />
                <ErrorMessage name="phone" className={errorClasses} component="div" />
              </div>
              <div
                role="group"
                aria-labelledby="my-radio-group"
                className="flex flex-col gap-y-4 lg:flex-row justify-between"
              >
                <div className="">I'm interested in</div>
                <div className="flex flex-col gap-4 xl:gap-8 items-start lg:flex-row">
                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="interest"
                      value="Residential Apartments"
                      checked={values.interest === 'Residential Apartments' ? true : false}
                      className={`${radioClasses} ${
                        values.interest === 'Residential Apartments' && 'bg-pink'
                      }`}
                    />
                    Residential Apartments
                  </label>
                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="interest"
                      value="Retail Space"
                      checked={values.interest === 'Retail Space' ? true : false}
                      className={`${radioClasses} ${
                        values.interest === 'Retail Space' && 'bg-pink'
                      }`}
                    />
                    Retail Space
                  </label>
                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="interest"
                      value="Food & Beverage Space"
                      checked={values.interest === 'Food & Beverage Space' ? true : false}
                      className={`${radioClasses} ${
                        values.interest === 'Food & Beverage Space' && 'bg-pink'
                      }`}
                    />
                    Food & Beverage Space
                  </label>
                </div>
              </div>

              <div className={`flex justify-end mt-2`}>
                <button className="text-black bg-pink rounded-full py-2 px-10" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};
