import { useStaticQuery, graphql } from "gatsby"

export const useLatestPosts = () => {
  const { allSanityBlogPost } = useStaticQuery(graphql`
    query {
      allSanityBlogPost(limit: 2, sort: { fields: date, order: DESC }) {
        edges {
          node {
            date(formatString: "DD MMM YYYY")
            main {
              title
              slug {
                current
              }
            }
            category {
              main {
                title
              }
            }
            portalImage {
              image {
                ...ImageWithPreview
              }
            }
          }
        }
      }
    }
  `);
  return allSanityBlogPost.edges || []
}


