import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "@popmotion/popcorn"




const SingleImageCarousel = ({ children }) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const wrappedIndex = wrap(0, children.length, slideIndex)
  const Length = children.length - 1

  return (
    <div className="relative">
      <AnimatePresence exitBeforeEnter>
        <div
          {...children[wrappedIndex]}
          key={slideIndex}
          drag="x"
          dragMomentum={false}
          dragElastic={false}
          dragConstraints={{ left: 0, right: 0 }}
          onDragEnd={(e, { offset, velocity }) => {
            if (offset.x > 100) {
              setSlideIndex(slideIndex - 1)
            } else if (offset.x < -100) {
              setSlideIndex(slideIndex + 1)
            }
          }}
          transition={{ duration: 0.3 }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {children[wrappedIndex]}
          </motion.div>
        </div>
      </AnimatePresence>
      {children.length > 1 && (
        <div className="absolute top-0 left-0 font-serif text-lg md:text-2xl z-10 pl-grid pt-grid">
          {slideIndex + 1}
          <span className="px-2">of</span>
          {children.length}
        </div>
      )}
      {children.length > 1 && (
        <nav className="absolute right-0 bottom-0 flex justify-end items-end pb-grid pr-grid z-10">
          <motion.button
            className={`outline-remove ${
              slideIndex === 0 && "opacity-50 pointer-events-none"
            }`}
            onClick={() => setSlideIndex(slideIndex - 1)}
          >
            <svg
              className="w-8 lg:w-16"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="27"
                cy="27"
                r="27"
                transform="rotate(-180 27 27)"
                fill="#F95D5D"
              />
              <path
                d="M24.2918 35L25.172 34.0972L18.6043 27.2917L37.8335 27.2917L37.8335 26.0417L18.6043 26.0417L25.172 19.2361L24.2918 18.3333L16.1668 26.6667L24.2918 35Z"
                fill="white"
              />
            </svg>
          </motion.button>
          <motion.button
            className={`outline-remove ${
              slideIndex === Length && "opacity-50 pointer-events-none"
            }`}
            onClick={() => setSlideIndex(slideIndex + 1)}
          >
            <svg
              className="w-8 lg:w-16 ml-4"
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="27" cy="27" r="27" fill="#F95D5D" />
              <path
                d="M29.7082 19L28.828 19.9028L35.3957 26.7083H16.1665V27.9583H35.3957L28.828 34.7639L29.7082 35.6667L37.8332 27.3333L29.7082 19Z"
                fill="white"
              />
            </svg>
          </motion.button>
        </nav>
      )}
    </div>
  )
}

export default SingleImageCarousel
