import React from 'react'
import { Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image"
import { motion, AnimatePresence } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"
import { MotionCarousel } from "../carousels"
import { PageLink } from "../ui"
import { easeOut } from '@popmotion/popcorn'

const aniVars = {
  hidden: {
    opacity: 0,
    x: 45,
  },
  visible: {
    x: 0,
    opacity: 1,    
  },
};

const headingVars = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const ProjectsSlider = ({ heading, spacing, items }) => {

  function onPan(event, info) {
    console.log(info.offset)
    var value = info.offset * 1.5
    return value
  }

  return (
    <div>
      <MotionWraper className={`grid grid-cols-14 gap-y-8 overflow-x-hidden`} spacing={spacing}>
        <motion.div
          transition={{
            type: 'ease-out',
            damping: 10,
            delay: 0.6,
            duration: 0.9,
          }}
          variants={headingVars}
          className="z-0 row-start-1 col-start-2 col-end-14 lg:col-end-4 flex items-end lg:items-start justify-between"
        >
          {heading && (
            <h3
              style={{ lineHeight: 1 }}
              className="w-1/2 lg:w-full text-xl lg:text-5xl tracking-wider font-bold uppercase lg:transform lg:rotate-90 lg:origin-bottom-left lg:-translate-y-full"
            >
              {heading}
            </h3>
          )}
          <Link className="text-sm lg:hidden underline" to={`/projects`}>
            View Projects
          </Link>
        </motion.div>
        <motion.div
          transition={{
            type: 'ease-out',
            delay: 0.3,
            duration: 0.6,
          }}
          variants={aniVars}
          className="z-10 row-start-2 lg:row-start-1 col-start-2 col-end-14 lg:col-start-4 lg:col-end-12"
        >
          <AnimatePresence>
            <MotionCarousel className={`w-full cursor-drag`}>
              {items.map((item) => {
                return (
                  <>
                    {item._type == 'projectSlide' ? (
                      <Link
                        to={`/projects/${item.link.page.main.slug.current}`}
                        className={`cursor-drag no-underline group w-full pr-4 lg:pr-12 flex-slide-item`}
                      >
                        {item.image && (
                          <motion.div className="overflow-hidden">
                            <div className="overflow-hidden">
                              <motion.div
                                whileHover={{ scale: 1.05 }}
                                transition={{
                                  duration: 0.5,
                                  type: 'ease-in-out',
                                }}
                              >
                                <div className="aspect-w-12 aspect-h-8">
                                  <Image {...item.image.image} />
                                </div>
                              </motion.div>
                            </div>
                            <div className="cursor-pointer flex flex-col justify-between text-sm lg:text-base mt-4">
                              {item.link && (
                                <h4 className="font-medium no-underline block mt-4 lg:mt-6 text-lg 2xl:text-3xl md:text-2xl">
                                  {item.link.linkText}
                                </h4>
                              )}
                              <span className="underline hidden md:block opacity-0 group-hover:opacity-100 transition duration-200">
                                View project
                              </span>
                            </div>
                          </motion.div>
                        )}
                      </Link>
                    ) : (
                      <a
                        href={`${item.link.link}`}
                        target="_blank"
                        rel="noreferrer"
                        className={`cursor-drag no-underline group w-full pr-4 lg:pr-12 flex-slide-item`}
                      >
                        {item.image && (
                          <motion.div className="overflow-hidden">
                            <div className="overflow-hidden">
                              <motion.div
                                whileHover={{ scale: 1.05 }}
                                transition={{
                                  duration: 0.5,
                                  type: 'ease-in-out',
                                }}
                              >
                                <div className="aspect-w-12 aspect-h-8">
                                  <Image {...item.image.image} />
                                </div>
                              </motion.div>
                            </div>
                            <div className="cursor-pointer flex flex-col justify-between text-sm lg:text-base mt-4">
                              {item.link && (
                                <h4 className="font-medium no-underline block mt-4 lg:mt-6 text-lg 2xl:text-3xl md:text-2xl">
                                  {item.link.linkText}
                                </h4>
                              )}
                              <span className="underline hidden md:block opacity-0 group-hover:opacity-100 transition duration-200">
                                View website
                              </span>
                            </div>
                          </motion.div>
                        )}
                      </a>
                    )}
                  </>
                );
              })}
            </MotionCarousel>
          </AnimatePresence>
        </motion.div>
      </MotionWraper>
    </div>
  );
}

export default ProjectsSlider
