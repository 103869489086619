import React from 'react'
import Image from "gatsby-plugin-sanity-image"

import { motion } from "framer-motion"

// Components
import{MotionWraper} from './motionWraper'
import { PortableTextBlock } from "../sanity/portableTextBlock"


const aniVars = {
  hidden: {
    y: 10,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const ImageText = ({ slug, _rawText, image, spacing, reverseLayout }) => {
  var imgCol = '';
  var textCol = '';
  if (reverseLayout === true) {
    textCol = `lg:pr-8`;
    imgCol = `lg:pl-8`;
  } else {
    textCol = `lg:pl-8`;
    imgCol = `lg:pr-8`;
  }

  return (
    <MotionWraper spacing={spacing}>
      <motion.div
        id={slug && slug.current && slug.current}
        className={`px-grid flex flex-wrap lg:flex-nowrap items-start ${
          reverseLayout === true && 'flex-row-reverse'
        }`}
        variants={aniVars}
      >
        <div className={`w-full lg:w-1/2 ${imgCol}`}>
          {image && <Image className="w-full h-auto" {...image} />}
        </div>
        <div
          data-scroll
          data-scroll-sticky
          data-scroll-position="top"
          data-scroll-target={`#${slug && slug.current && slug.current}`}
          className={`mt-8 lg:mt-0 w-full lg:w-1/2 lg:sticky top-8 ${textCol}`}
        >
          {_rawText && (
            <PortableTextBlock
              className="text-current prose lg:prose-lg 2xl:prose-xl max-w-none"
              text={_rawText}
            />
          )}
        </div>
      </motion.div>
    </MotionWraper>
  );
};

export default ImageText
