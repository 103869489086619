import React from 'react'
import { Link } from "gatsby"
import Image from "gatsby-plugin-sanity-image"

// Components
import { StandardBlockWrapper } from "./standardBlockWrapper"
import { BlogCard } from "../ui"

const RelatedJournal = ({ items }) => {
  return (
    <div>
      <StandardBlockWrapper
        className={`grid grid-cols-14 gap-y-8 lg:gap-y-16 pb-24 lg:pb-48`}
      >
        <div className="z-20 row-start-1 col-start-2 col-end-14 flex items-end justify-between">
          <h3 className="w-1/2 text-xl lg:text-5xl tracking-wider font-bold uppercase">
            Other News
          </h3>
          <Link className="text-sm underline lg:font-medium" to={`/journal`}>
            View Journal
          </Link>
        </div>
        <div className="row-start-2 col-start-2 col-end-14 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-y-16 lg:gap-x-8 lg:gap-y-20">
          {items.edges.map(item => (
            <BlogCard item={item} />
          ))}
        </div>
      </StandardBlockWrapper>
    </div>
  )
}

export default RelatedJournal
