import React, { useState } from "react"

import { motion, AnimatePresence } from "framer-motion"
import ReactPlayer from 'react-player/vimeo';

import blockStyles from "./videoModule.module.css"

// SVG
import PlayIcon from "../../svg/play.svg"

export const VideoModule = ({ video }) => {
  const [isActive, setActive] = useState(false)


  return (
    <div className="w-full bg-black text-white">
      <AnimatePresence exitBeforeEnter>
        <motion.div className={`${blockStyles.wrapper}`}>
          {!isActive && (
            <button
              className="absolute z-10 top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-25"
              onClick={() => setActive(true)}
            >
              <div>
                <PlayIcon />
              </div>
            </button>
          )}
          <ReactPlayer
            url={video}
            playing={isActive}
            muted={!isActive}
            loop={!isActive}
            controls={isActive}
            width="100%"
            height="auto"
            onEnded={() => setActive(false)}
            playsinline={!isActive}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
}
