import React from 'react';
import PropTypes from 'prop-types';

import {
  Cta,
  ContactDetails,
  ImageCarousel,
  ImageGrid,
  ImageSlider,
  ImageText,
  PageBanner,
  PartnerGrid,
  PeopleSlider,
  LatestJournal,
  ProjectPortal,
  ProjectsSlider,
  Quotes,
  SingleImage,
  SingleQuote,
  Specifications,
  TextBlock,
  TextBlockTwoCol,
  VideoBlock,
  PopUp,
  EnquiryForm,
} from './';

const BlockZone = ({ blocks }) => {
  const block = blocks.map((block) => {
    switch (block._type) {
      case 'contactDetails':
        return <ContactDetails {...block} />;
      case 'cta':
        return <Cta {...block} />;
      case 'imageCarousel':
        return <ImageCarousel {...block} />;
      case 'imageGrid':
        return <ImageGrid {...block} />;
      case 'imageSlider':
        return <ImageSlider {...block} />;
      case 'imageText':
        return <ImageText {...block} />;
      case 'journalsSlider':
        return null;
      case 'latestPosts':
        return <LatestJournal {...block} />;
      case 'pageBanner':
        return <PageBanner {...block} />;
      case 'partnerGrid':
        return <PartnerGrid {...block} />;
      case 'peopleSlider':
        return <PeopleSlider {...block} />;
      case 'projectPortal':
        return <ProjectPortal {...block} />;
      case 'projectsSlider':
        return <ProjectsSlider {...block} />;
      case 'quotes':
        return <Quotes {...block} />;
      case 'singleImage':
        return <SingleImage {...block} />;
      case 'singleQuote':
        return <SingleQuote {...block} />;
      case 'specifications':
        return <Specifications {...block} />;
      case 'textBlock':
        return <TextBlock {...block} />;
      case 'textBlockTwoCol':
        return <TextBlockTwoCol {...block} />;
      case 'videoBlock':
        return <VideoBlock {...block} />;
      case 'popUp':
        return <PopUp {...block} />;
      case 'enquiryForm':
        return <EnquiryForm {...block} />;
      default:
        return null;
    }
  });
  return <>{block}</>;
};

BlockZone.propTypes = {
  blocks: PropTypes.array.isRequired,
};

export default BlockZone;
