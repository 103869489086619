import React from 'react'
import { motion } from 'framer-motion';
import Image from "gatsby-plugin-sanity-image"

// Components
import { MotionWraper } from "./motionWraper"
import { MotionCarousel } from "../carousels"


const aniVars = {
  hidden: {
    opacity: 0,
    x: '15%',
  },
  visible: {
    opacity: 1,
    x: 0,    
  },
};

const headingVars = {
  hidden: {
    opacity: 0,
    y: 40,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
};


const ImageSlider = ({ heading, spacing, items }) => {


  return (
    <MotionWraper
      className={`overflow-x-hidden grid grid-cols-14 lg:grid-rows-1`}
      spacing={spacing}
    >
      <motion.div
        variants={headingVars}
        transition={{
          type: 'ease-in-out',
          damping: 7,
          delay: 0.3,
          duration: 0.5,
        }}
        className="row-start-1 col-start-2 col-end-14 md:col-end-8 lg:col-end-5 pr-16 z-0"
      >
        <h3 className="text-2xl lg:text-3xl mb-8">{heading}</h3>
      </motion.div>
      <motion.div
        variants={aniVars}
        transition={{
          type: 'ease-in-out',
          delay: 0.3,
          duration: 0.6,
        }}
        className="row-start-2 lg:row-start-1 col-start-2 col-end-14 lg:col-start-6"
      >
        <MotionCarousel className={`z-10 cursor-drag carouselVisible`}>
          {items.map((item) => (
            <div className="w-full lg:w-auto pr-4 lg:pr-16 slide-item" style={{ flex: '0 0 auto' }}>
              <div>
                {item.image && (
                  <div className="relative">
                    <Image {...item.image} className="h-80 md:h-96 lg:h-100 xl:h-110" />
                  </div>
                )}
                {item.caption && <p className="mt-4 lg:mt-6 text-lg md:text-2xl">{item.caption}</p>}
              </div>
            </div>
          ))}
        </MotionCarousel>
      </motion.div>
    </MotionWraper>
  );
}

export default ImageSlider
