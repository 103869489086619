import React from 'react'

import { PageBannerDesk, PageBannerMobile } from "./"

import { useMediaQuery } from "@react-hook/media-query"


const PageBanner = ({ outlineText, solidText, imageWidth, image }) => {

  const isDesk = useMediaQuery("(min-width: 1024px)")
  return (
    <>
      <PageBannerMobile
        className={`lg:hidden`}
        outlineText={outlineText}
        solidText={solidText}
        image={image}
      />
      <PageBannerDesk
        className={`hidden lg:block`}
        outlineText={outlineText}
        solidText={solidText}
        image={image}
        imageWidth={imageWidth}
      />
      {isDesk ? <>
      </> : <></>}
    </>
  )
}

export default PageBanner
