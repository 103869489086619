import React from 'react'
import { motion } from "framer-motion"
import Image from "gatsby-plugin-sanity-image"

// Components
import { MotionWraper } from './motionWraper';

// Animation
const imageVariants = {
  visible: {
    opacity: 1,
    scale:1,
  },
  hidden: {
    opacity: 0,
    scale:0.90,
  },
}

const SingleImage = ({ spacing, alignment, image }) => {
  

  var alignImage = ""
  if (alignment === "left") {
    alignImage = `col-start-2 col-end-14 md:col-end-11`
  }
  if (alignment === "right") {
    alignImage = `col-start-2  col-end-14 md:col-start-5`
  }  
  if (alignment === "full") {
    alignImage = `col-start-2 col-end-14`
  }
  if (alignment === 'centered') {
    alignImage = `col-start-2 col-end-14 lg:col-start-3 lg:col-end-13 2xl:col-start-4 2xl:col-end-12`;
  }

  return (    
    <MotionWraper className={`grid grid-cols-14 overflow-y-visible`} spacing={spacing}>
      {image && (
        <motion.div
          className={`${alignImage}`}            
          variants={imageVariants}
          transition={{
            type: 'ease-out',
            damping: 10,
            delay: 0,
            duration: 0.8,
          }}
        >
          <Image {...image} />
        </motion.div>
      )}
    </MotionWraper>
  );
}

export default SingleImage
