import React from 'react'
import { motion } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"

// Animation
const variants = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: 20,
  },
}

const SingleQuote = ({spacing, quote, alignment }) => {

  var alignContent = ""
  if (alignment === "left") {
    alignContent = `lg:col-end-12 3xl:col-end-11`
  }
  if (alignment === "right") {
    alignContent = `lg:col-start-4 3xl:col-start-5`
  }
  if (alignment === "center") {
    alignContent = `lg:col-start-4 lg:col-end-12`
  } 

  return (
      <MotionWraper className={`grid grid-cols-14`} spacing={spacing}>
        <div className={`col-start-2 col-end-14 ${alignContent}`}>
            {quote.quoteText && (
              <motion.h2                
                variants={variants}
                transition={{
                  type: "ease-out",
                  damping: 10,
                  delay: 0,
                  duration: 0.5,
                }}
                className="font-serif text-lg md:text-xl lg:text-3xl xl:text-5xl"
              >
                {quote.quoteText}
              </motion.h2>
            )}
            {quote.quoteCredit && (
              <motion.h5                
                variants={variants}
                transition={{
                  type: "ease-out",
                  damping: 10,
                  delay: 0.2,
                  duration: 0.5,
                }}
                className="text-xs md:text-base lg:text-lg font-sans mt-4"
              >
                {quote.quoteCredit}
              </motion.h5>
            )}
        </div>
      </MotionWraper>
  )
}

export default SingleQuote
