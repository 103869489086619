import React from "react"
import Image from "gatsby-plugin-sanity-image"
import { motion } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"
import { MotionCarousel } from "../carousels"

// Animation
const wrapVariants = {
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay:0.5,
      staggerChildren: 0.9,
    },
  },
  hidden: {
    opacity: 0,
    x: 0,    
  },
};

const bioVariants = {
  visible: {
    opacity: 1,
    x:0,
    transition: {
      duration: 0.6,      
    },
  },
  hidden: {
    x:"10%",
    opacity: 0,    
  },
};

const headingVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
      delay:0.5,
    },
  },
  hidden: {
    y: '10%',
    opacity: 0,
  },
};

const PeopleSlider = ({ heading, items, spacing }) => {



  return (
    <MotionWraper className={`grid grid-cols-14 gap-y-8 overflow-x-hidden`} spacing={spacing}>
      <motion.div variants={headingVariants} className="z-0 row-start-1 col-start-2 col-end-14 lg:col-end-6 flex items-end lg:items-start">
        {heading && (
          <h3
            style={{ lineHeight: 1 }}
            className="w-1/2 lg:w-full text-xl lg:text-5xl tracking-wider font-bold uppercase lg:transform lg:rotate-90 lg:origin-bottom-left lg:-translate-y-full"
          >
            {heading}
          </h3>
        )}
      </motion.div>
      <motion.div
        variants={wrapVariants}
        className="row-start-2 lg:row-start-1 col-start-2 col-end-14 lg:col-start-4 md:col-end-12 lg:col-end-9"
      >
        <MotionCarousel className={`z-10 w-full cursor-drag`}>
          {items.map((item) => {
            const { image, name, role } = item;
            return (
              <div className="w-full pr-12" style={{ flex: '0 0 auto' }}>
                <motion.div variants={bioVariants}>
                  {image && (
                    <div className="w-full relative aspect-w-16 aspect-h-16 object-cover">
                      <Image {...image} className="absolute inset-0 object-cover" />
                    </div>
                  )}
                  <h3 className="mt-5 mb-2 text-lg md:text-2xl">{name}</h3>
                  <p className="font-serif text-md">{role}</p>
                </motion.div>
              </div>
            );
          })}
        </MotionCarousel>
      </motion.div>
    </MotionWraper>
  );
}

export default PeopleSlider
