import React, { useState, useEffect } from 'react';
import Image from 'gatsby-plugin-sanity-image';
import useCookie from 'react-use-cookie';

import { motion, AnimatePresence } from 'framer-motion';

import { PortableTextBlock } from '../sanity/portableTextBlock';

const parentVars = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: 3,
    },
  },
};

const childVars = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      delay: 0.8,
    },
  },
};

const PopUp = ({ _key, _rawPopupText, image, bgColor, textColor, exitLink, setCookie }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [cookieDismissed, setCookieDismissed] = useCookie(`${_key}_dismissed`, false);

  const dismissPopup = () => {
    setShowPopup(false);
    if (setCookie) {
      setCookieDismissed(true);
    }
  };

  useEffect(() => {
    if (setCookie && !cookieDismissed) {
      setShowPopup(true);
    } else if (!setCookie) {
      setShowPopup(true);
    }
  }, []);

  return (
    <>
      <AnimatePresence>
        {showPopup && (
          <motion.div
            className="w-full h-full fixed top-0 left-0 bg-black bg-opacity-80 px-gutter z-2000 popup py-10"
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={parentVars}
          >
            <div className="w-full h-full absolute top-0 left-0" onClick={dismissPopup} />
            <motion.div
              className="w-full max-w-2xl mx-auto bg-white relative"
              style={{ backgroundColor: bgColor && bgColor.hex, color: textColor && textColor.hex }}
              variants={childVars}
            >
              <button
                className="w-10 h-10 flex items-center justify-center absolute top-4 right-4 z-10 rounded-full text-xl"
                style={{ backgroundColor: bgColor && bgColor.hex }}
                onClick={dismissPopup}
              >
                x
              </button>
              {image && image.asset && <Image {...image} className="w-full relative z-0" />}
              <div className="pt-4 px-12 pb-20">
                <div className="text-center prose prose-lg">
                  <PortableTextBlock text={_rawPopupText} />
                </div>
                {exitLink && exitLink.linkText && (
                  <a
                    href={exitLink.link}
                    target="_blank"
                    style={{
                      backgroundColor: textColor && textColor.hex,
                      color: bgColor && bgColor.hex,
                    }}
                    className="p-4 flex w-full justify-center uppercase tracking-wide text-lg"
                  >
                    {exitLink.linkText}
                  </a>
                )}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default PopUp;
