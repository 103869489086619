import React from 'react'
import { motion } from "framer-motion"

// Components
import { MotionWraper } from "./motionWraper"

// Animation
const specWrapVariants = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  hidden: {
    opacity: 0,
    transition: { staggerChildren: 0.3, staggerDirection: -1 },
  },
}

const specVariants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
    },
  },
  hidden: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.3,
    },
  },
}

const Specifications = ({ spacing, items }) => {

  return (
      <MotionWraper className={`grid grid-cols-14`} spacing={spacing}>
        {items && (
          <motion.div
            variants={specWrapVariants}            
            className="col-start-2 col-end-14 grid grid-flow-row grid-cols-2 lg:grid-cols-4 gap-x-4 gap-y-8 md:gap-y-16"
          >
            {items.map(item => {
              return (
                <motion.div variants={specVariants} className="flex flex-col">
                  {item.specLabel && (
                    <span className="font-bold text-xs lg:text-sm mb-2">
                      {item.specLabel}
                    </span>
                  )}
                  {item.specValue && (
                    <span className="font-serif text-xl lg:text-2xl">
                      {item.specValue}
                    </span>
                  )}
                </motion.div>
              )
            })}
          </motion.div>
        )}
      </MotionWraper>
  )
}

export default Specifications
