import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { UseFooter } from '../../GraphQL/useFooter';

// Components
import { StandardBlockWrapper } from './standardBlockWrapper';
import { PortableTextBlockContact } from '../sanity/portableTextBlockContact';
import { ContactForm } from '../forms/contactForm';

const headingVariants = {
  initial: {
    opacity: 0,
    y: '100px',
    rotateX: '-80deg',
  },
  animate: {
    opacity: 1,
    y: '0',
    rotateX: '0deg',
  },
};

const textVariants = {
  initial: {
    opacity: 0,
    y: 15,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
};

const ContactDetails = ({ outlineText, solidText, _rawContactDetails, spacing }) => {
  const { facebook, instagram } = UseFooter();

  return (
    <AnimatePresence exitBeforeEnter>
      <StandardBlockWrapper className={`grid grid-cols-14 min-h-screen`} spacing={spacing}>
        <motion.div
          className="z-20 col-start-2 col-end-14 row-start-2 text-sm md:text-base mt-12 lg:mt-0 lg:row-start-1 lg:col-start-8"
          initial="initial"
          animate="animate"
          exit="initial"
          variants={textVariants}
          transition={{
            type: 'ease-in-out',
            damping: 10,
            delay: 0.8,
            duration: 0.9,
          }}
        >
          <ContactForm />
        </motion.div>
        <div className="col-start-2 col-end-14 mt-10 lg:col-end-10 lg:row-start-1">
          <div>
            {_rawContactDetails && <PortableTextBlockContact text={_rawContactDetails} />}
            <strong className="text-xs lg:text-base font-sans block font-bold mb-2 tracking-wide">
              Follow Us
            </strong>
            {facebook && (
              <a
                className="font-serif text-lg md:text-xl lg:text-2xl 2xl:text-4xl mr-6"
                target="_blank"
                rel="noreferrer"
                href={facebook}
              >
                Facebook
              </a>
            )}
            {instagram && (
              <a
                className="font-serif text-lg md:text-xl lg:text-2xl 2xl:text-4xl"
                target="_blank"
                rel="noreferrer"
                href={instagram}
              >
                Instagram
              </a>
            )}
          </div>
          <h1
            style={{ lineHeight: '1' }}
            className="transform translate-y-10 lg:translate-y-20 z-10 flex flex-col justify-end text-7xl md:text-8xl lg:text-9xl xl:text-9xl 2xl:text-11xl font-sans font-bold uppercase pr-8 lg:pr-0 text-current"
          >
            <motion.span
              className="outline-heading origin-center"
              initial="initial"
              animate="animate"
              exit="initial"
              variants={headingVariants}
              transition={{
                ease: 'easeInOut',
                delay: 0.2,
                duration: 0.7,
              }}
            >
              {outlineText && outlineText}
            </motion.span>
            <motion.span
              className="origin-center"
              initial="initial"
              animate="animate"
              exit="initial"
              variants={headingVariants}
              transition={{
                ease: 'easeInOut',
                delay: 0.4,
                duration: 0.65,
              }}
            >
              {solidText && solidText}
            </motion.span>
          </h1>
        </div>
      </StandardBlockWrapper>
    </AnimatePresence>
  );
};

export default ContactDetails;
