import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export const ContactForm = () => {
  const inputClasses =
    'bg-transparent border-b rounded-none border-black w-full placeholder-current outline-none focus:outline-none py-2';

  const errorClasses = 'tracking-wide text-sm my-2 text-left';
  return (
    <Formik
      initialValues={{
        firstName: '',
        surname: '',
        email: '',
        phone: '',
        project: '',
        subscribe: true,
      }}
      validate={(values) => {
        const errors = {};
        // Validate Name Field
        if (!values.firstName) {
          errors.firstName = 'First Name is Required';
        }
        if (!values.phone) {
          errors.phone = 'Phone Number is required';
        }
        // Validate Email Address
        if (!values.email) {
          errors.email = 'Email address is Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setStatus }) => {
        console.log(values);
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': `contact`,
            formID: 'contact',
            ...values,
          }),
        })
          .then(() => {
            setSubmitting(false);
            setStatus('success');
          })
          .catch((error) => alert(error));
      }}
    >
      {({
        onChange,
        isSubmitting,
        status,
        values,
        /* and other goodies */
      }) => (
        <Form
          className="w-full relative mt-10 text-wonder-green"
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          subject="Urban Collective Enquiry"
        >
          {/* Hidden Fields for netlify */}
          <input type="hidden" name="contact" value="contact" />
          <p className="hidden">
            <label>
              <input name="bot-field" onChange={onChange} />
            </label>
          </p>
          <>
            <div
              className={`prose lg:prose-xl text-center text-black transition-all delay-300 duration-300 absolute top-0 left-0 right-0 z-10 ${
                status === 'success'
                  ? 'opacity-100 pointer-events-all'
                  : 'opacity-0 pointer-events-none'
              }`}
            >
              <p>Thank you we will be in touch</p>
            </div>
            <div
              className={`grid gap-x-12 grid-cols-1 font-body text-medium transition-all duration-300 gap-y-6 ${
                status === 'success' && 'opacity-0 pointer-events-none'
              }`}
            >
              <div>
                <Field
                  type="text"
                  name="firstName"
                  className={inputClasses}
                  placeholder="First Name"
                />
                <ErrorMessage name="firstName" className={errorClasses} component="div" />
              </div>
              <div>
                <Field type="text" name="surname" className={inputClasses} placeholder="Surname" />
              </div>
              <div>
                <Field
                  type="email"
                  name="email"
                  className={inputClasses}
                  placeholder="Email Address"
                />
                <ErrorMessage name="email" className={errorClasses} component="div" />
              </div>
              <div>
                <Field
                  type="text"
                  name="phone"
                  className={inputClasses}
                  placeholder="Phone Number"
                />
                <ErrorMessage name="phone" className={errorClasses} component="div" />
              </div>
              <div>
                <Field as="select" name="project" className={inputClasses}>
                  <option value="I am enquiring about:">I am enquiring about:</option>
                  <option value="Wonder Apartments">Wonder Apartments</option>
                  <option value="Pompallier on Ponsonby">Pompallier on Ponsonby</option>
                  <option value="Other">Other</option>
                </Field>
              </div>
              <div>
                <Field
                  type="text"
                  name="message"
                  className={inputClasses}
                  placeholder="Message"
                  component="textarea"
                />
                <ErrorMessage name="phone" className={errorClasses} component="div" />
              </div>
              <div>
                <label className="flex items-center">
                  <Field
                    type="checkbox"
                    name="subscribe"
                    className="mr-2 appearance-none w-4 h-4 border border-black checked:bg-black outline-none focus:outline-none"
                  />
                  <div>Subscribe to project updates</div>
                </label>
              </div>
              <div className={`flex justify-end mt-2`}>
                <button className="text-pink bg-black rounded-full py-2 px-10" type="submit">
                  Submit
                </button>
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};
