import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { UseFooter } from '../../GraphQL/useFooter';

// Components
import { StandardBlockWrapper } from './standardBlockWrapper';
import { PortableTextBlockContact } from '../sanity/portableTextBlockContact';
import { EnquireForm } from '../forms/enquireForm';

const EnquiryForm = ({ heading, submissionText, spacing }) => {

  return (
    <AnimatePresence exitBeforeEnter>
      <StandardBlockWrapper className={`grid grid-cols-14`} spacing={spacing}>
        <div className="z-20 col-start-2 col-end-14 row-start-2 text-sm md:text-base mt-12 lg:mt-0 lg:row-start-1 xl:col-start-5 3xl:col-start-8">
          {heading && (
            <h3 className="font-sans text-2xl lg:text-4xl xl:text-5xl font-bold">{heading}</h3>
          )}
          <EnquireForm submissionText={submissionText} />
        </div>
      </StandardBlockWrapper>
    </AnimatePresence>
  );
};

export default EnquiryForm;
