import React from 'react'

// Components
import { StandardBlockWrapper } from "./standardBlockWrapper"
import { QuotesCarousel } from "../carousels"

const Quotes = ({ spacing, items }) => {
  return (
    <StandardBlockWrapper className={`grid grid-cols-14`} spacing={spacing}>
      <QuotesCarousel
        className={`col-start-2 col-end-14 lg:col-end-12 2xl:col-end-10`}
      >
        {items.map(item => (
          <div className="w-full">
            {item.quoteText && (
              <h2 className="font-serif text-lg md:text-xl lg:text-3xl xl:text-5xl">
                {item.quoteText}
              </h2>
            )}
            {item.quoteCredit && (
              <h5 className="text-xs md:text-base lg:text-lg font-sans mt-4">
                {item.quoteCredit}
              </h5>
            )}
          </div>
        ))}
      </QuotesCarousel>
    </StandardBlockWrapper>
  )
}

export default Quotes
