import React from 'react'

// Components
import { StandardBlockWrapper } from "./standardBlockWrapper"
import { ProjectCard, ProjectCardExternal } from '../ui';

const ProjectPortal = ({ spacing, heading, items }) => {  

  return (
    <div className="">
      <StandardBlockWrapper
        className={`grid grid-cols-14 pt-24 lg:pt-48`}
        spacing={{ padBot: "3" }}
      >
        {heading && (
          <h1 className="col-start-2 col-end-14 text-center text-4xl font-bold lg:text-8xl 2xl:text-10xl uppercase mb-24 lg:mb-48 2xl:py-20">
            {heading}
          </h1>
        )}
        <div className="col-start-2 col-end-14 grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-y-16 lg:gap-x-8 lg:gap-y-20 ">
          {items && (
            <>
              {items.map(item => (
                <>
                {item._type == 'projectPortalItem' ? (
                  <ProjectCard {...item} />
                ) : (
                  <ProjectCardExternal {...item} />
                )}
                </>
              ))}
            </>
          )}
        </div>
      </StandardBlockWrapper>
    </div>
  )
}

export default ProjectPortal
